import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { IListItem } from 'data-transfers/dto'
import { useField } from 'formik'

import { MultiListItemSelect } from 'components/selects'
import { IMultiListItemsAutocomplete } from 'components/selects/MultiListItemSelect/types'

const SelectListItemMulti = ({
  name,
  onChangeValue,
  ...props
}: Pick<
  IMultiListItemsAutocomplete,
  | 'label'
  | 'placeholder'
  | 'required'
  | 'helperText'
  | 'className'
  | 'limit'
  | 'labelTooltip'
  | 'isClearable'
  | 'isDraggable'
  | 'type'
  | 'tooltip'
> &
  Required<Pick<IMultiListItemsAutocomplete, 'name'>> & {
    onChangeValue?: () => void
  }) => {
  const [field, , { setValue }] = useField<IListItem[] | null>(name)

  const selectedItems = useMemo(() => {
    return (
      field.value?.map<IListItem>(({ id, value }) => ({
        id: id ?? '',
        value: value ?? ''
      })) ?? []
    )
  }, [field.value])

  const onChange = useCallback<Dispatch<SetStateAction<IListItem[]>>>(
    (setStateAction) => {
      const items =
        typeof setStateAction === 'function'
          ? setStateAction(selectedItems)
          : setStateAction
      setValue(
        items.map<IListItem>(
          (item) => ({
            id: item.id,
            value: item.value
          }),
          true
        )
      )

      onChangeValue?.()
    },
    [onChangeValue, selectedItems, setValue]
  )

  return (
    <MultiListItemSelect
      selectedItems={selectedItems}
      name={name}
      onChange={onChange}
      industriesFilterIds={['58da0027-1b4f-401d-c05c-08d98104d557']}
      {...props}
    />
  )
}
export default SelectListItemMulti
